import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import styles from './styles.module.scss'

function renderImage(file) {
  return (
      <Img className={styles.image} fluid={file.node.childImageSharp.fluid} />
  )
}

// Search for proper image file by name.
const Image = function ({ imgName }) {
  
  // Remove path and leave only image name.
  const imgFileName = imgName ? imgName.replace(/^.*[\\/]/, '') : '';
  
  return <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {sourceInstanceName: {eq: "uploads"}}) {
          edges {
            node {
              extension
              relativePath
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let image = data.images.edges.find(
        image => image.node.relativePath === imgFileName
      );
      if (!image) {
        image = data.images.edges.find(
            image => image.node.relativePath === "placeholder.jpg"
        );
      }
      return(renderImage(image))
    }}
  />
};

export default Image;
